import { novaStar } from "~/assets/assets";

export function Appbar() {
  return (
    <div class="flex items-center gap-1 text-white">
      <img src={novaStar} alt="Star Logo" class="h-8 w-8" />
      <span class="text-lg font-bold ">PLUTO</span>
      <span class="rounded-full border border-white px-2 py-0.5 text-[10px] font-semibold">
        BETA
      </span>
    </div>
  );
}
